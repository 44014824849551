import {Tooltip, Button, FormControlLabel, FormGroup, Switch, Typography, TextField, IconButton, Select, MenuItem, Grid, Divider,FormControl, InputLabel} from "@material-ui/core"
import {useState, useContext} from "react";
import axiosAuth from "../../../utils/axiosWithAuth";
import TextractContext from "../../../contexts/TextractContext"
import Queries from "./Queries"
import {HelpOutline} from '@material-ui/icons';
import InvoyageTooltip from "../bill/InvoyageTooltip";
import SplitRules from "./SplitRules";

export default function Configuration() {
  const {CONFIGURATION_BASE, snackbar, setSnackbar, selectedConfiguration, setSelectedConfiguration, configurations, setConfigurations, groupedConfigurations, utilityCompanies} = useContext(TextractContext)


  const handleQueryChange = e => {
    const { name, value } = e.target;
    const [changedProperty, changedQueryId] = name.split("-")



    setSelectedConfiguration(prevState => {
      const changedQuery = prevState.queries.find(q => `${q.id}` === changedQueryId)
      
      return {
        ...prevState,
        queries: [...prevState.queries.filter(q => `${q.id}` != changedQuery.id), {
          ...changedQuery,
          [changedProperty]: value,
          changed: true
        }].sort((a,b) => a.id - b.id)
      }
    })
  }

  const handleSplitRuleChange = e => {
    const { name, value } = e.target;
    const [changedProperty, changedSplitRuleId] = name.split("-")



    setSelectedConfiguration(prevState => {
      const changedRule = prevState.splitRules.find(q => `${q.id}` === changedSplitRuleId)
      
      return {
        ...prevState,
        splitRules: [...prevState.splitRules.filter(q => `${q.id}` != changedRule.id), {
          ...changedRule,
          [changedProperty]: value
        }].sort((a,b) => a.id - b.id)
      }
    })
  }
  const handleSave = config => {
    axiosAuth().post(`/admin/textract_configurations/${config.id}/save`, config)
    .then(r => {
      setSnackbar({message: "Saved configuration", severity: "success" })
      setConfigurations(prevState => [
        ...prevState.filter(c => c.id != config.id),
        selectedConfiguration
      ])
    }).catch(err => {
      setSnackbar({message: "Failed to save configuration", severity: "error"})
    })
  }

  const handleSwitchToggle = e => {
    setSelectedConfiguration(prevState => ({
      ...prevState,
      [e.target.name]: e.target.checked
    }))
  }

  const handleNewQuery = config => {
    axiosAuth().post(`/admin/textract_configurations/${config.id}/queries/new`)
      .then(r => {
        setSelectedConfiguration(prevState => ({
          ...prevState,
          queries: [...prevState.queries, r.data.query]
        }))
      }).catch(err => console.log(err))
  }

  const handleDeleteQuery = (query) => {
    axiosAuth().delete(`/admin/textract_configurations/queries/${query.id}`)
      .then(r => {
        setSelectedConfiguration(prevState => ({
          ...prevState,
          queries: prevState.queries.filter(q => +q.id != +query.id)
        }))
      }).catch(err => console.log(err))
  }

  const handleDeleteSplitRule = (rule) => {
    axiosAuth().delete(`/admin/textract_configurations/split_rules/${rule.id}`)
      .then(r => {
        setSelectedConfiguration(prevState => ({
          ...prevState,
          splitRules: prevState.splitRules.filter(sr => sr.id != rule.id)
        }))
      }).catch(err => console.log(err))
  }

  const handleNewSplitRule = config => {
    const newRule = {
      id: Date.now(), // Temporary ID
      rule_type: "start_end",
      start_condition: "",
      end_condition: "",
      line_count: null,
      textract_configuration_id: config.id
    };
    setSelectedConfiguration(prevState => ({
      ...prevState,
      splitRules: [...prevState.splitRules, newRule]
    }))
  }
  
  const handleConfigChange = e => {
    setSelectedConfiguration(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }
  const handleConfigSelect = (e) => {
    axiosAuth().get("/admin/textract_configurations/" + e.target.value).then(r => {
      setSelectedConfiguration(r.data.configuration)
    })
  }
  const handleNewConfiguration = _ => {

    axiosAuth().post(`/admin/textract_configurations/new`, CONFIGURATION_BASE)
      .then(r => {
        setConfigurations(prevState => [...prevState, r.data.configuration])
        setSelectedConfiguration({...r.data.configuration, queries: [], splitRules: [] } )
      })
      .catch(console.error)
  }
  return (
    <div>
      <Typography variant="h4">Manage Configuration&nbsp;
      <InvoyageTooltip title="Update a query configuration to extract any data that's not captured in the provided Tables. For example, usage or demand that aren't correctly parsed in tables." arrow>
        <IconButton color='primary'>
          <HelpOutline />
        </IconButton>
      </InvoyageTooltip>
      <Select
        value={selectedConfiguration && selectedConfiguration.id}
        variant="outlined"
        onChange={handleConfigSelect}
      >
        {groupedConfigurations}
      </Select>
      <Button onClick={handleNewConfiguration}>Create New +</Button> 
      
      </Typography> 
      <div style={{display: 'flex', justifyContent: 'center'}} >
        <TextField
            InputLabelProps={{ shrink: true }}
            style={{width: 500, marginRight: 20}}
            margin="normal"
            id="name"
            label="Name"
            name="name"
            disabled={selectedConfiguration.name == "Invoice Metadata"}
            value={selectedConfiguration.name || ""}
            onChange={handleConfigChange}
        />
        {selectedConfiguration.name != "Invoice Metadata" && 
          <Select
            value={selectedConfiguration && selectedConfiguration.utility_company_id}
            variant="outlined"
            margin="normal"
            style={{width: 500, marginRight: 20, height: 56, marginTop: 16}}
            onChange={handleConfigChange}
            name="utility_company_id"
          >
            <MenuItem key='null-uc' value="999999">None</MenuItem>
            {utilityCompanies.sort((a,b) => a.name.localeCompare(b.name)).map(uc => <MenuItem key={uc.id} value={uc.id}>{uc.name}</MenuItem>)}
          </Select>
        }
        <FormGroup>
          <FormControlLabel control={<Switch
            name="use_forms"
            checked={selectedConfiguration.use_forms}
            onChange={handleSwitchToggle}
            />} label="Forms" />
          <FormControlLabel control={<Switch 
            name="use_tables"
            checked={selectedConfiguration.use_tables}
            onChange={handleSwitchToggle}
            />} label="Tables" />
          <FormControlLabel control={<Switch 
            name="use_expense"
            checked={selectedConfiguration.use_expense}
            onChange={handleSwitchToggle}
          />} label="Expenses" />
          <FormControlLabel control={<Switch
            name="use_queries"
            checked={selectedConfiguration.use_queries}
            onChange={handleSwitchToggle}
            />} label="Queries" />
        </FormGroup>
      </div>
      {/* /* gets_usage_from_queries: false,
       provides_sewer_usage: false, 
       storm_water_always_one_esu: false, 
       no_lighting_usage: false */}
      {selectedConfiguration && !(selectedConfiguration.name == "Invoice Metadata") &&
       // Start of  Selection
       <>
          <Grid container justifyContent="center" spacing={1}>
            <Grid item xs={12} md={2} style={{ display: 'flex', justifyContent: 'center' }}>
              <FormControl variant="outlined" fullWidth margin="normal">
                <InputLabel id="gpt-bill-data-source-label">GPT Data Source</InputLabel>
                <Select
                  labelId="gpt-bill-data-source-label"
                  id="gpt-bill-data-source"
                  value={selectedConfiguration.gpt_bill_data_source || ""}
                  onChange={handleConfigChange}
                  name="gpt_bill_data_source"
                  label="GPT Data Source"
                >
                  <MenuItem value="tables">Tables</MenuItem>
                  <MenuItem value="html">HTML</MenuItem>
                  <MenuItem value="pdf">PDF</MenuItem>
                  <MenuItem value="structured-pdf">Structured PDF</MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="outlined" fullWidth margin="normal">
                <InputLabel id="ai-model-label">AI Model</InputLabel>
                <Select
                  labelId="ai-model-label"
                  id="ai-model"
                  value={selectedConfiguration.ai_model || ""}
                  onChange={handleConfigChange}
                  name="ai_model"
                  label="AI Model"
                >
                  <MenuItem value="grok-2-latest">Grok 2</MenuItem>
                  <MenuItem value="gpt-4-turbo">GPT-4 Turbo (most used)</MenuItem>
                  <MenuItem value="o3-mini-high">o3-mini-high</MenuItem>
                  <MenuItem value="o3-mini-medium">o3-mini-medium</MenuItem>
                  <MenuItem value="o3-mini-low">o3-mini-low</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Divider style={{margin: 20}} />
          <Grid container justify="center" spacing={1}>
            <Grid item xs={2}>
              <FormControlLabel control={<Switch
                name="no_lighting_usage"
                checked={selectedConfiguration.no_lighting_usage}
                onChange={handleSwitchToggle}
                />} label="No Lighting Usage" />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel control={<Switch 
                name="storm_water_always_one_esu"
                checked={selectedConfiguration.storm_water_always_one_esu}
                onChange={handleSwitchToggle}
                />} label="Default Storm Water to one ESU" />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel control={<Switch 
                name="provides_sewer_usage"
                checked={selectedConfiguration.provides_sewer_usage}
                onChange={handleSwitchToggle}
              />} label="Provides Sewer Usage" />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel control={<Switch
                name="gets_usage_from_queries"
                checked={selectedConfiguration.gets_usage_from_queries}
                onChange={handleSwitchToggle}
                />} label="Gets Usage From Queries" />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel control={<Switch
                name="force_single_date_range"
                checked={selectedConfiguration.force_single_date_range}
                onChange={handleSwitchToggle}
                />} label="Force Single Date Range" />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel control={<Switch
                name="get_invoice_date_from_ai"
                checked={selectedConfiguration.get_invoice_date_from_ai}
                onChange={handleSwitchToggle}
                />} label="Get Invoice Date from AI" />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel control={<Switch
                name="get_total_charges_from_ai"
                checked={selectedConfiguration.get_total_charges_from_ai}
                onChange={handleSwitchToggle}
                />} label="Get Total Charges from AI" />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel control={<Switch
                name="skip_ocr"
                checked={selectedConfiguration.skip_ocr}
                onChange={handleSwitchToggle}
                />} label="Skip OCR" />
            </Grid>
          </Grid>

          <Divider style={{ margin: 16 }} />
          <Typography variant="h6">Validations</Typography>

          <Grid container style={{textAlign: 'left'}} spacing={1}>
            <Grid item xs={2} justifyContent='flex-start'>
              <FormControlLabel
                control={
                  <Switch
                    name="skip_nonzero_charge_validation"
                    checked={selectedConfiguration.skip_nonzero_charge_validation}
                    onChange={handleSwitchToggle}
                  />
                }
                label="Skip Nonzero Charge Validation"
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Switch
                    name="skip_usage_validation"
                    checked={selectedConfiguration.skip_usage_validation}
                    onChange={handleSwitchToggle}
                  />
                }
                label="Skip Usage Validation"
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Switch
                    name="skip_demand_validation"
                    checked={selectedConfiguration.skip_demand_validation}
                    onChange={handleSwitchToggle}
                  />
                }
                label="Skip Demand Validation"
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Switch
                    name="skip_demand_history_validation"
                    checked={selectedConfiguration.skip_demand_history_validation}
                    onChange={handleSwitchToggle}
                  />
                }
                label="Skip Demand History Validation"
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Switch
                    name="skip_duplicate_usage_validation"
                    checked={selectedConfiguration.skip_duplicate_usage_validation}
                    onChange={handleSwitchToggle}
                  />
                }
                label="Skip Duplicate Usage Validation"
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Switch
                    name="skip_total_charge_validation"
                    checked={selectedConfiguration.skip_total_charge_validation}
                    onChange={handleSwitchToggle}
                  />
                }
                label="Skip Total Charge Validation"
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Switch
                    name="skip_invoice_date_validation"
                    checked={selectedConfiguration.skip_invoice_date_validation}
                    onChange={handleSwitchToggle}
                  />
                }
                label="Skip Invoice Date Validation"
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Switch
                    name="skip_inactive_uam_validation"
                    checked={selectedConfiguration.skip_inactive_uam_validation}
                    onChange={handleSwitchToggle}
                  />
                }
                label="Skip Inactive UAM Validation"
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Switch
                    name="skip_neighboring_bill_validation"
                    checked={selectedConfiguration.skip_neighboring_bill_validation}
                    onChange={handleSwitchToggle}
                  />
                }
                label="Skip Neighboring Bill Validation"
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Switch
                    name="skip_usage_threshold_validation"
                    checked={selectedConfiguration.skip_usage_threshold_validation}
                    onChange={handleSwitchToggle}
                  />
                }
                label="Skip Usage Threshold Validation"
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Switch
                    name="skip_demand_threshold_validation"
                    checked={selectedConfiguration.skip_demand_threshold_validation}
                    onChange={handleSwitchToggle}
                  />
                }
                label="Skip Demand Threshold Validation"
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Switch
                    name="skip_one_day_period_validation"
                    checked={selectedConfiguration.skip_one_day_period_validation}
                    onChange={handleSwitchToggle}
                  />
                }
                label="Skip One Day Period Validation"
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Switch
                    name="skip_delivery_cadence_validation"
                    checked={selectedConfiguration.skip_delivery_cadence_validation}
                    onChange={handleSwitchToggle}
                  />
                }
                label="Skip Delivery Cadence Validation"
              />
            </Grid>
          </Grid>
        </>
      }
      <Queries handleNewQuery={handleNewQuery} handleQueryChange={handleQueryChange} handleDeleteQuery={handleDeleteQuery} queries={selectedConfiguration.queries} />
      <SplitRules handleNewSplitRule={handleNewSplitRule} handleSplitRuleChange={handleSplitRuleChange}  handleDeleteSplitRule={handleDeleteSplitRule} splitRules={selectedConfiguration.splitRules} />
      {/* basic config form */}

      <Button variant="contained" onClick={_ => handleSave(selectedConfiguration)}>Save Configuration</Button>


    </div>
  )
}