import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, Divider } from '@material-ui/core';
import epDataUsers from '../epDataUsers';

const ValidationSelector = ({ createdBy, setCreatedBy }) => {
  return (
    <FormControl variant="outlined" style={{ width: 300 }}>
      <InputLabel id="created-by-label">Created By</InputLabel>
      <Select
        label="Created By"
        labelId="created-by-label"
        value={createdBy}
        onChange={(e) => setCreatedBy(e.target.value)}
        displayEmpty
      >
        <MenuItem value="all">All</MenuItem>
        <MenuItem value="Auto">System</MenuItem>
        <MenuItem value="Manual">Manual</MenuItem>
        <Divider />
        {epDataUsers.map(user => (
          <MenuItem key={user.key} value={user.value}>
            {user.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ValidationSelector;
