import { useState, useEffect, useCallback, useMemo } from "react";
import ValidationChart from "./ValidationChart";
import ValidationAppBar from "./ValidationAppBar";
import axiosAuth from "../../../utils/axiosWithAuth";
import MeterInfo from "../bill/form/MeterInfo";
import ValidationSelector from "./ValidationSelector";
import TabPanel from "../../request-emails/TabPanel";
import ValidationSourceSelector from "./ValidationSourceSelector";
import ValidationCreatedBySelector from "./ValidationCreatedBySelector";
import { TextField, Slider, Typography, Grid} from "@material-ui/core";
import { debounce } from 'lodash';

export default function Validation() {
  const INITIAL_DATE = new Date(Date.now() - 86400000).toISOString().split("T")[0]
  const [chartData, setChartData] = useState([]);
  const [selectedUam, setSelectedUam] = useState(null);
  const [source, setSource] = useState("all")
  const [createdBy, setCreatedBy] = useState("all")
  const [selectedUamId, setSelectedUamId] = useState(null);
  const [uams, setUams] = useState([]);
  const [loading, setLoading] = useState(true)
  const [validationTabIndex, setValidationTabIndex] = useState(0)
  const [date, setDate] = useState(
    INITIAL_DATE
  );

  const fetchData = (date, createdBy, source) => {
    setLoading(true)
    axiosAuth()
    .post(`/admin/textract_bills/validation/uams/`, {date, createdBy, source})
    .then((res) => {
      console.log("Res from /admin/textract_bills/validation/billdata/uams", res);
      setUams(res.data.uams);
      if (res.data.uams && res.data.uams.length > 0) {
        setSelectedUamId(res.data.uams[0].id);
      } else {
        setSelectedUamId(null);
      }
      setLoading(false)
    })
    .catch((err) => {console.log(err) ; setLoading(false)});
  }

  // Fetch list of UAMs based on selected date
  useEffect(() => {
    const effectiveDate = validationTabIndex === 1 ? "all" : date;
    const effectiveCreatedBy = validationTabIndex === 1 ? "all" : createdBy;
    const effectiveSource = validationTabIndex === 1 ? null : source == "all" ? null : source;
    fetchData(effectiveDate, effectiveCreatedBy, effectiveSource);
  }, [date, validationTabIndex, createdBy, source]);

  // Fetch chart data & selected UAM details
  const fetchBillData = useCallback(
    (id) => {
      if (!id) {
        setChartData([]);
        setSelectedUam(null);
        return;
      }

      axiosAuth()
        .get(`/admin/textract_bills/validation/billdata/${id}`)
        .then((res) => {
          console.log(
            `Res from /admin/textract_bills/validation/billdata/${id}`,
            res
          );
          setChartData(res.data.billData);
          setSelectedUam(res.data.uam);
        })
        .catch((err) => console.log(err));
    },
    [setChartData, setSelectedUam]
  );

  const debouncedFetchBillData = useMemo(() => {
    return debounce((id) => fetchBillData(id), 300);
  }, [fetchBillData]);

  useEffect(() => {
    debouncedFetchBillData(selectedUamId);

    return () => {
      debouncedFetchBillData.cancel();
    };
  }, [selectedUamId, debouncedFetchBillData]);

  // Current index in the uams array
  const currentIndex = uams.findIndex((uam) => uam.id === selectedUamId);
  const totalUams = uams.length;

  // Handler for Slider change
  const handleSliderChange = (event, newIndex) => {
    setSelectedUamId(uams[newIndex].id);
  };

  return (
    <div className="validation-container">
      <ValidationAppBar validationTabIndex={validationTabIndex} setValidationTabIndex={setValidationTabIndex} />
      <br />
      <TabPanel value={validationTabIndex} index={0} >
        <Grid container spacing={2} alignItems="center" justifyContent='center' style={{ marginBottom: 20, marginLeft: 20 }}>
          <Grid item>
            <TextField
              id="date-picker"
              label="Date Processed"
              type="date"
              defaultValue={date}
              onChange={(e) => setDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item>
            <ValidationSourceSelector
              source={source}
              setSource={setSource}
            />
          </Grid>
          <Grid item>
            <ValidationCreatedBySelector
              createdBy={createdBy}
              setCreatedBy={setCreatedBy}
            />
          </Grid>
          <Grid item>
            <ValidationSelector
              uams={uams}
              selectedUamId={selectedUamId}
              setSelectedUamId={setSelectedUamId}
              loading={loading}
            />
          </Grid>
          <Grid item xs={3} style={{ textAlign: "center", opacity: totalUams > 0 && currentIndex >= 0 ? 1 : 0}}>
            <Typography variant="body1" gutterBottom>
              Viewing UAM {currentIndex + 1} of {totalUams} {currentIndex + 1 == totalUams && <Typography variant="span">🎉</Typography> }
            </Typography>
            <Slider
              value={currentIndex + 1}
              onChange={(event, value) => handleSliderChange(event, value - 1)}
              step={1}
              min={1}
              max={totalUams}
              valueLabelDisplay="auto"
            />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={validationTabIndex} index={1} >
        <Grid container spacing={2} alignItems="center" justifyContent='space-around' style={{ marginBottom: 20, marginLeft: 20 }}>
          <Grid item>
            <ValidationSelector
              uams={uams}
              selectedUamId={selectedUamId}
              setSelectedUamId={setSelectedUamId}
              loading={loading}
            />
          </Grid>
        </Grid>
      </TabPanel>
     

      <div style={{ display: "flex", justifyContent: "center" }}>
        <div>
          <ValidationChart dataRows={chartData} uam={selectedUam} />
        </div>
        {selectedUam && <MeterInfo uam={selectedUam} />}
      </div>
    </div>
  );
}
