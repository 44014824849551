import axios from "axios";

const baseUrl = process.env.REACT_APP_EP_API_URL;

const axiosAuth = () => {
    const token = localStorage.getItem('jwt')
    const widgetToken = localStorage.getItem("widgetJwt")
    return axios.create({
        baseURL: baseUrl || "",
        timeout: 300000,
        headers: {
            Authorization: token,
            widgetToken: widgetToken
        }
    })
}

axiosAuth.CancelToken = axios.CancelToken;
axiosAuth.isCancel = axios.isCancel;

export default axiosAuth;