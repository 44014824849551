import { groupBy } from "../../../../../helpers/arrayHelpers";

let cachedGroups = null;
let lastGptBillSignature = null;
let lastUamsSignature = null;

const getGptBillSignature = (gptBill) => {
  if (!gptBill) return "null";
  const chargeIds = gptBill.charges.map(c => `${c.id}-${c.utility_account_meter_id}-${c.utility_charge_type_id}-${c.start_date}-${c.end_date}-${c.amount}-${c.applicable}`).join("|");
  const usageIds = gptBill.usages.map(u => `${u.id}-${u.utility_account_meter_id}-${u.utility_usage_type_id}-${u.start_date}-${u.end_date}-${u.amount}-${u.applicable}`).join("|");
  return `${gptBill.id || "0"}-${chargeIds}-${usageIds}`;
};

const getUamsSignature = (uams) => {
  if (!uams || !uams.length) return "empty";
  return uams.map(uam => `${uam.id}-${uam.energy_type_id}`).join("|");
};

const buildUamLookup = (uams) => {
  const lookup = new Map();
  for (const uam of uams) {
    lookup.set(uam.id, uam);
  }
  return lookup;
};

export const buildLineItemGroups = function (gptBill, uams) {


  const gptBillSignature = getGptBillSignature(gptBill);
  const uamsSignature = getUamsSignature(uams);

  if (
    cachedGroups !== null &&
    gptBillSignature === lastGptBillSignature &&
    uamsSignature === lastUamsSignature
  ) {
    console.log("Returning cached groups");
    return cachedGroups;
  }

  if (!gptBill || !uams?.length) {
    cachedGroups = [];
    lastGptBillSignature = gptBillSignature;
    lastUamsSignature = uamsSignature;
    return cachedGroups;
  }

  const uamLookup = buildUamLookup(uams);

  const groupedCharges = groupBy(gptBill.charges, (c) => c.utility_account_meter_id);
  const groupedUsages = groupBy(gptBill.usages, (u) => u.utility_account_meter_id);

  const uamIdsMap = new Map();
  for (const uamId in groupedCharges) uamIdsMap.set(+uamId, true);
  for (const uamId in groupedUsages) uamIdsMap.set(+uamId, true);
  const chargeUsageUamIds = Array.from(uamIdsMap.keys()).sort((a, b) => a - b);

  const groups = chargeUsageUamIds.map((uamId) => {
    const uam = uamLookup.get(uamId) || null;
    const name = !uam
      ? "Loading uams..."
      : `${uam.meter_num} ${uam.display_name} ${
          uam.association_type !== "STANDARD" ? uam.association_type : ""
        }`;
    return {
      name,
      utility_account_meter: uam,
      energy_type_id: uam?.energy_type_id ?? null,
      charges: groupedCharges[uamId] || [],
      usages: groupedUsages[uamId] || [],
    };
  });

  const sortedGroups = groups.sort((a, b) => (a.energy_type_id || 0) - (b.energy_type_id || 0));

  cachedGroups = sortedGroups;
  lastGptBillSignature = gptBillSignature;
  lastUamsSignature = uamsSignature;

  return sortedGroups;
};



export const buildGptBillFromUtilityBill = function (textractBill) {
  const { ep_bill, gpt_bill } = textractBill;
  if (!ep_bill || !gpt_bill) {
    return null;
  }

  const gptChargeMap = new Map(gpt_bill.charges.map((c) => [c.id, { ...c }]));
  const gptUsageMap = new Map(gpt_bill.usages.map((u) => [u.id, { ...u }]));

  ep_bill.charges.forEach((c) => {
    const gptCharge = gptChargeMap.get(c.gpt_charge_id);
    if (gptCharge) gptCharge.applicable = true;
  });
  ep_bill.usages.forEach((u) => {
    const gptUsage = gptUsageMap.get(u.gpt_usage_id);
    if (gptUsage) gptUsage.applicable = true;
  });

  const unappliedCharges = Array.from(gptChargeMap.values()).filter((c) => !c.applicable);
  const unappliedUsages = Array.from(gptUsageMap.values()).filter((u) => !u.applicable);

  const newBill = {
    ...ep_bill,
    charges: ep_bill.charges.map((charge) => {
      const gptCharge = gptChargeMap.get(charge.gpt_charge_id);
      return {
        ...charge,
        applicable: true,
        description: gptCharge ? gptCharge.description : "Manually Added Charge",
        meter_num: gptCharge?.meter_num ?? charge.meter_num,
      };
    }),
    usages: ep_bill.usages.map((usage) => {
      const gptUsage = gptUsageMap.get(usage.gpt_usage_id);
      return {
        ...usage,
        applicable: true,
        description: gptUsage ? gptUsage.description : "Manually Added Usage",
        meter_num: gptUsage?.meter_num ?? usage.meter_num,
        measurement_unit: gptUsage?.measurement_unit ?? usage.measurement_unit,
        estimated: gptUsage?.estimated ?? usage.estimated,
      };
    }),
  };
  newBill.charges.push(...unappliedCharges);
  newBill.usages.push(...unappliedUsages);

  return newBill;
};