import React, { useState, useEffect, useContext, useCallback } from 'react';
import axiosAuth from '../../utils/axiosWithAuth';
import { 
  Tooltip, FormControl, InputLabel, Select, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, 
    Paper, TablePagination, TableSortLabel, TextField, Link, Button, Typography, Alert, IconButton
} from '@material-ui/core';
import { BILL_STATUS_OPTIONS
 } from '../helpers/constants';

import InvoyageTooltip from './bill/InvoyageTooltip';
import InvoyageStatus from './bill/InvoyageStatus'
import EditIcon from '@material-ui/icons/Edit';
import {Link as RouterLink} from "react-router-dom"
import {getFormattedDateTZ} from "../helpers/date-helpers"
import TextractContext from '../../contexts/TextractContext';
import IndexLink from './IndexLink';
import { PictureAsPdf } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import UserContext, { useUser } from '../../contexts/UserContext';
import {parseYYYYMMDDToLocaleString} from "../helpers/date-helpers"
import PrimaryColorLoader from '../PrimaryColorLoader';
import FilterCompanySelect from './FilterCompanySelect';
import epDataUsers from './epDataUsers';
import RefreshIcon from '@material-ui/icons/Refresh';
import {debounce} from "lodash";

function PaginatedTable({setSelectedTextractBillId}) {
  const {activeTheme} = useContext(UserContext)
  const {user} = useUser();
  const useStyles = makeStyles({
    tableCell: {
      padding: "6px 16px",
      fontSize: '1.4rem',
      border: activeTheme.palette.mode == "dark" ? '1px solid #555': '1px solid #ccc'
    },
    row: {
      '&:nth-child(odd)': {
        backgroundColor: activeTheme.palette.mode == "dark" ? "#323232" : '#f4f6f8',  // Light gray
      },
      '&:hover': {
        backgroundColor: activeTheme.palette.mode == "dark" ? "#444" : '#ddd', // Use theme-based value
      },
    },
    header: {
      backgroundColor: activeTheme.palette.mode == "dark" ? "#444" :'#e0e0e0', // Slightly darker gray for the header
      fontWeight: 'bold',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Optional: slight shadow for depth
      textTransform: 'uppercase',
    }
    
  });
  
  const classes = useStyles();
  const {filters, setFilters, fetchNew, setFetchNew, setSnackbar } = useContext(TextractContext)
  const [bills, setBills] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [utilities, setUtilities] = useState([])
  const [deliveryIds, setDeliveryIds] = useState([])
  const [error, setError] = useState(false)
  const [billsLoading, setBillsLoading] = useState(true)

  useEffect(() => {
    fetchTextractBills();
  }, [filters, fetchNew]);

  const fetchTextractBills = () => {
    if (fetchNew) setFetchNew(false);
    axiosAuth().post(`/admin/textract_bills/search`, filters)
      .then(response => {
        const {textractBills, totalCount, delivery_ids} = response.data;
        setDeliveryIds(delivery_ids);
        if (textractBills) {
          if (!textractBills.length && filters.page !== 0) {
            setFilters({...filters, page: 0});
          }
          setBills(textractBills);
          setTotalCount(totalCount);
        } else {
          setBills([]);
          localStorage.removeItem("invoyageFilters");
          setTotalCount(0);
          setError(true);
        }
        setBillsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
  };

  useEffect(_ => {
    axiosAuth().get("/admin/textract_bills/mappings/utilities")
      .then(r => setUtilities(r.data.utilities))
      .catch(err => console.log(err.response))
  },[])

  const handleSortChange = (field) => {
    console.log("Hi from handleSortPage")
    console.log(filters.order)
    const isAsc = filters.sort === field && filters.order === 'asc';
    console.log("isAsc", isAsc)
    const sortParams = { sort: field, order: isAsc ? 'desc' : 'asc' }
    console.log(sortParams)
    setFilters({...filters, ...sortParams});
  };

  const handleChangePage = (event, newPage) => {
    console.log("Hi from handleChangePage")

    setFilters({...filters, page: newPage})
  };
  
  const handleChangeRowsPerPage = (event) => {
    console.log("Hi from handleChangeRowsPerPage")
    setFilters({...filters, page: 0, rowsPerPage: +event.target.value})
  };

  const handleFilterChange = e => {
    console.log(e.target.name, e.target.value)
    let resetPageParams = {}
   
    setFilters({...filters, [e.target.name]: e.target.value, ...resetPageParams})
  }

  const handleAutoProcess = (params) => {
    let interval;
    setSnackbar({ message: `Auto processing ${JSON.stringify(params)}...`, severity: "info", loader: null });

    interval = setInterval(() => {
      setFetchNew(true);
    }, 2000);
    
    axiosAuth().post(`admin/textract_bills/auto_process`, params)
        .then(r => {
          clearInterval(interval); // Stop the interval when the request is successful
          setFetchNew(true)
        })
        .catch(err => {
          clearInterval(interval); // Stop the interval in case of an error
          setFetchNew(true)
          console.log(err);
        });
};


  const onClick = id => {
    setSelectedTextractBillId(id)
  }
  const handlePdfClick = tb => {
    axiosAuth().post(`/admin/textract_bills/file`, {bill: tb})
    .then(r=> {
      console.log(r)
      window.open(r.data.link)
    })
    .catch(console.log)
  }

  return (
    <>
    {error && <Alert severity="error">Something went wrong. Please refresh your browser.</Alert>}
      <Paper>
        <div style={{display: 'flex', justifyContent: "space-between", alignItems: 'center', padding: 10, border: `1px solid ${activeTheme.palette.mode == "dark" ? "#555" : "#ccc"}`, borderWidth: "1px 1px 0px 1px", borderRadius: "15px 15px 0px 0px"}}>
          <div style={{display: 'flex'}}>
            <Typography variant='h3' style={{marginLeft: 16, paddingLeft: 50}}>
              Invoyage Bills
            </Typography>
            <IconButton onClick={fetchTextractBills}>
              <RefreshIcon />
            </IconButton>
          </div>
          <div className="index-filters" style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
            <FormControl variant="outlined" style={{marginLeft: 10, width: 150, height: 56, textAlign: 'left'}}>
              <InputLabel id="delivery-id-label" htmlFor="delivery-id-select">Delivery</InputLabel>
              <Select
                label="Delivery"
                labelId="delivery-label"
                id="delivery-id-select"
                variant="outlined"
                value={filters.delivery_id}
                onChange={handleFilterChange}
                
                name="delivery_id"
                >
                  <MenuItem key="All" value="All">All</MenuItem>
                  {deliveryIds.map(deliveryId => 
                    <MenuItem key={deliveryId} value={deliveryId}>{parseYYYYMMDDToLocaleString(deliveryId)}</MenuItem>
                  )}

              </Select>
            </FormControl>
            <FormControl variant="outlined" style={{marginLeft: 10, textAlign: 'left'}}>
              <InputLabel id="processed-by-label" htmlFor="processed-by-select">Processed By</InputLabel>
              <Select
                label="Processed By"
                labelId="processed-by-label"
                id="processed-by-select"
                variant="outlined"
                value={filters.processed_by_user_id}
                onChange={handleFilterChange}
                style={{ width: 250, height: 56}}
                name="processed_by_user_id"
              >
                <MenuItem key={"all-option-created-by"} value={"all"}>All</MenuItem>
                {epDataUsers.map(user => (
                  <MenuItem key={user.key} value={user.value}>
                    {user.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" style={{marginLeft: 10, textAlign: 'left'}}>
              <FilterCompanySelect
                filters={filters} 
                handleFilterChange={handleFilterChange} 
                utilities={utilities}
              />
            </FormControl>
            <FormControl variant="outlined" style={{marginLeft: 10, width: 150, height: 56, textAlign: 'left'}}>
              <InputLabel id="status-label" htmlFor="status-select">Status</InputLabel>
              <Select
                label="Status"
                labelId="status-label"
                id="status-select"
                variant="outlined"
                value={filters.status}
                onChange={handleFilterChange}
                
                name="status"
                >
                {Object.entries(BILL_STATUS_OPTIONS).map(pair => 
                  <MenuItem key={pair[0]} value={pair[0]}>{pair[1]}</MenuItem>
                )}

              </Select>
            </FormControl>
            <TextField
              label="Search"
              variant="outlined"
              name="query"
              value={filters.query}
              onChange={handleFilterChange}
              style={{ margin: '16px' }}
            />
          </div>
        </div>
        {
        billsLoading 
        ? <PrimaryColorLoader width={50} height={50} />
        : bills.length == 0 
          ? <Typography variant='h1'>No bills found</Typography>
          : <Table>
            <TableHead>
              <TableRow className={classes.header}>
                <TableCell className={classes.tableCell}>
                  <TableSortLabel
                    active={filters.sort === 'tb.id'}
                    direction={filters.order}
                    onClick={() => handleSortChange('tb.id')}
                  >
                    ID
                  </TableSortLabel>
                </TableCell>
                {/* <TableCell className={classes.tableCell}>
                  <TableSortLabel
                    active={filters.sort === 's3_key'}
                    direction={filters.order}
                    onClick={() => handleSortChange('s3_key')}
                  >
                    File
                  </TableSortLabel>
                </TableCell> */}
                <TableCell className={classes.tableCell}>
                  <TableSortLabel
                    active={filters.sort === 'combined_utility_company_name'}
                    direction={filters.order}
                    onClick={() => handleSortChange('combined_utility_company_name')}
                  >
                    Utility
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <TableSortLabel
                    active={filters.sort === 'combined_acct_num'}
                    direction={filters.order}
                    onClick={() => handleSortChange('combined_acct_num')}
                  >
                    Account Number
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <TableSortLabel
                    active={filters.sort === 'combined_meter_num'}
                    direction={filters.order}
                    onClick={() => handleSortChange('combined_meter_num')}
                  >
                    Meter Number
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <TableSortLabel
                    active={filters.sort === 'gptb.start_date'}
                    direction={filters.order}
                    onClick={() => handleSortChange('gptb.start_date')}
                  >
                    Start Date
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <TableSortLabel
                    active={filters.sort === 'gptb.end_date'}
                    direction={filters.order}
                    onClick={() => handleSortChange('gptb.end_date')}
                  >
                    End Date
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <TableSortLabel
                    active={filters.sort === 'tb.invoice_date'}
                    direction={filters.order}
                    onClick={() => handleSortChange('tb.invoice_date')}
                  >
                    Invoice Date
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableCell} style={{textAlign: 'left'}}>
                  <TableSortLabel
                    active={filters.sort === 'tb.delivery_id'}
                    direction={filters.order}
                    onClick={() => handleSortChange('tb.delivery_id')}
                  >
                    Delivery ID
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableCell} style={{textAlign: 'center'}}>
                  <TableSortLabel
                    active={filters.sort === 'tb.status'}
                    direction={filters.order}
                    onClick={() => handleSortChange('tb.status')}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableCell} style={{textAlign: 'left'}}>
                  <TableSortLabel
                    active={filters.sort === 'tb.processed_at'}
                    direction={filters.order}
                    onClick={() => handleSortChange('tb.processed_at')}
                  >
                    Processed Date
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableCell}>  
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bills.map(bill => (
                <TableRow key={bill.id-JSON.stringify(filters)} className={classes.row}>
                  <TableCell className={classes.tableCell}>
                    <Link 
                    component={RouterLink}
                    style={{cursor: "pointer"}} 
                    onClick={onClick}
                    to={`/invoyage/${bill.id}`}>
                      {bill.id}
                      {bill.os_can_process && !bill.exclude_from_t1_processing ? <b>&nbsp;T1</b> : ""}
                    </Link>
                  </TableCell>
                  {/* <TableCell className={classes.tableCell}>
                    <Link 
                      component={RouterLink} 
                      to={`/invoyage/${bill.id}`} 
                      style={{cursor: "pointer"}} 
                      onClick={onClick}>
                        {bill.s3_key}
                    </Link>
                  </TableCell> */}
                  <TableCell className={classes.tableCell}>
                    <IndexLink
                      resourceName="utility_companies"
                      epName={bill.ep_utility_company_name }
                      resourceText={bill.utility_company_name}
                      resourceId={bill.utility_company_id}
                    />
                    {["GPT_PARSED", "ERROR"].includes(bill.status) && bill.utility_account_id && !user.os && bill.auto_process_in_invoyage &&
                    <InvoyageTooltip title={`Auto-Process ${bill.ep_utility_company_name}`} arrow>
                      <Button onClick={_ => handleAutoProcess({utility_company_id: bill.utility_company_id})}>🤖</Button>
                    </InvoyageTooltip>
                    }
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <IndexLink
                      resourceName="utility_accounts"
                      epName={bill.ep_acct_num}
                      resourceText={bill.acct_num}
                      resourceId={bill.utility_account_id}
                    />
                    {["GPT_PARSED", "ERROR"].includes(bill.status) && bill.utility_account_id && !user.os && bill.auto_process_in_invoyage &&
                    <InvoyageTooltip title={`Auto-Process ${bill.ep_acct_num}`} arrow>
                      <Button onClick={_ => handleAutoProcess({utility_account_id: bill.utility_account_id})}>🤖</Button>
                    </InvoyageTooltip>
                    }
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <IndexLink
                      resourceName="utility_meters"
                      epName={bill.ep_meter_num }
                      resourceText={bill.meter_num}
                      resourceId={bill.utility_meter_id}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell}>{getFormattedDateTZ(bill.gpt_start_date)}</TableCell>
                  <TableCell className={classes.tableCell}>{getFormattedDateTZ(bill.gpt_end_date)}</TableCell>
                  <TableCell className={classes.tableCell}>{getFormattedDateTZ(bill.invoice_date)}</TableCell>
                  <TableCell className={classes.tableCell}>
                    {bill.delivery_id && parseYYYYMMDDToLocaleString(bill.delivery_id) }
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <InvoyageStatus key={`${bill.id}-${bill.status}`} status={bill.status} title={bill.last_error_cause} />
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {bill.processed && bill.processed_at ?
                    <>
                      <span>{new Date(bill.processed_at).toLocaleDateString()} </span>
                      <span style={{ whiteSpace: 'nowrap' }}>{new Date(bill.processed_at).toLocaleTimeString()}</span>
                    </>
                  : null}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Link style={{cursor: "pointer"}} onClick={onClick}>
                      
                      <RouterLink to={`/invoyage/${bill.id}`} >
                        <Button variant='outlined'>
                          Edit <EditIcon />
                        </Button>
                      </RouterLink>
                    </Link>
                    <Link style={{cursor: "pointer"}} target="_blank" onClick={_ => handlePdfClick(bill)}>
                      <Button>
                        <PictureAsPdf />
                      </Button>
                    </Link>
                    </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>}
        <TablePagination
          component="div"
          count={totalCount}
          rowsPerPage={filters.rowsPerPage}
          page={filters.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}

export default PaginatedTable;
