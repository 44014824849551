const epDataUsers = [
  { key: 'karrie.ferrier@energyprint.com', value: 964, label: 'Karrie' },
  { key: 'jasonanthony', value: 6721, label: 'Jason Anthony' },
  { key: 'muneebcovers', value: 6179, label: 'Lauren' },
  { key: 'amanda.spice@energyprint.com', value: 221, label: 'Amanda' },
  { key: 'anastasia.gilday@energyprint.com', value: 5423, label: 'Anastasia' },
  { key: 'michael.harms@energyprint.com', value: 5690, label: 'Michael' },
  { key: 'matt.gamble@energyprint.com', value: 5977, label: 'Matt' },
  { key: 'katrina.kortman@energyprint.com', value: 6012, label: 'Kat' }
];

export default epDataUsers;