import React, { useContext, useState, useMemo } from "react";
import {
  Typography,
  Table,
  TableRow,
  TableBody,
  TableContainer,
  TableHead,
  TableCell,
  Paper,
  TableSortLabel,
  TextField,
} from "@material-ui/core";
import ApplicableCheckbox from "./form/ApplicableCheckbox";
import MappingTypeSelect from "./form/MappingTypeSelect";
import axiosAuth from "../../../utils/axiosWithAuth";
import UserContext from "../../../contexts/UserContext";
import {
  HIGHLIGHTED_ROW_STYLES,
  DARK_HIGHLIGHTED_ROW_STYLES,
} from "../../helpers/constants";

export default function MappingsEditor({ mappings, setMappings }) {
  const { activeTheme } = useContext(UserContext);

  // Sort states for Charges
  const [chargeSortBy, setChargeSortBy] = useState("created_at");
  const [chargeSortDirection, setChargeSortDirection] = useState("desc");

  // Sort states for Usages
  const [usageSortBy, setUsageSortBy] = useState("created_at");
  const [usageSortDirection, setUsageSortDirection] = useState("desc");

  // Filter state
  const [filterText, setFilterText] = useState("");

  // Handle column sort for Charges
  const handleChargeSort = (columnKey) => {
    if (chargeSortBy === columnKey) {
      setChargeSortDirection(chargeSortDirection === "asc" ? "desc" : "asc");
    } else {
      setChargeSortBy(columnKey);
      setChargeSortDirection("asc");
    }
  };

  // Handle column sort for Usages
  const handleUsageSort = (columnKey) => {
    if (usageSortBy === columnKey) {
      setUsageSortDirection(usageSortDirection === "asc" ? "desc" : "asc");
    } else {
      setUsageSortBy(columnKey);
      setUsageSortDirection("asc");
    }
  };

  // Generic sorting function
  const sortData = (data, sortBy, direction) => {
    return [...data].sort((a, b) => {
      let aVal = a[sortBy];
      let bVal = b[sortBy];

      // If we sorting by date
      if (sortBy === "created_at") {
        aVal = new Date(aVal);
        bVal = new Date(bVal);
      }

      if (aVal < bVal) return direction === "asc" ? -1 : 1;
      if (aVal > bVal) return direction === "asc" ? 1 : -1;
      return 0;
    });
  };

  // ----- Filter by description then sort for Charges -----
  const filteredChargeMappings = useMemo(() => {
    if (!filterText) return mappings.chargeMappings;
    return mappings.chargeMappings.filter((cm) =>
      cm.description.toLowerCase().includes(filterText.toLowerCase())
    );
  }, [mappings.chargeMappings, filterText]);

  const sortedChargeMappings = useMemo(() => {
    return sortData(filteredChargeMappings, chargeSortBy, chargeSortDirection);
  }, [filteredChargeMappings, chargeSortBy, chargeSortDirection]);

  // ----- Filter by description then sort for Usages -----
  const filteredUsageMappings = useMemo(() => {
    if (!filterText) return mappings.usageMappings;
    return mappings.usageMappings.filter((um) =>
      um.description.toLowerCase().includes(filterText.toLowerCase())
    );
  }, [mappings.usageMappings, filterText]);

  const sortedUsageMappings = useMemo(() => {
    return sortData(filteredUsageMappings, usageSortBy, usageSortDirection);
  }, [filteredUsageMappings, usageSortBy, usageSortDirection]);

  // Save handler
  const saveUpdate = (mapping, params, role) => {
    axiosAuth()
      .put(`/admin/textract_bills/mappings/${role}/${mapping.id}`, params)
      .then(console.log)
      .catch((e) => console.log(e));
  };

  return (
    <div className="mappings-editor">
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <Typography variant="h4" style={{ marginTop: 20, marginRight: 20}}>
          Mappings
        </Typography>

        {/* Text field for filtering */}
        <TextField
          label="Filter by Description"
          variant="outlined"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          style={{ marginTop: 16, marginBottom: 16 }}
        />
      </div>

      {/* ----- Charges Table ----- */}
      <Typography variant="h5" style={{ marginTop: 20 }}>
        Charges
      </Typography>
      <TableContainer style={{ marginTop: 10 }} component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="Charges Table">
          <TableHead>
            <TableRow>
              <TableCell
                sortDirection={chargeSortBy === "description" ? chargeSortDirection : false}
              >
                <TableSortLabel
                  active={chargeSortBy === "description"}
                  direction={chargeSortDirection}
                  onClick={() => handleChargeSort("description")}
                >
                  Description
                </TableSortLabel>
              </TableCell>

              <TableCell
                sortDirection={chargeSortBy === "utility_charge_type_id" ? chargeSortDirection : false}
              >
                <TableSortLabel
                  active={chargeSortBy === "utility_charge_type_id"}
                  direction={chargeSortDirection}
                  onClick={() => handleChargeSort("utility_charge_type_id")}
                >
                  Charge Type
                </TableSortLabel>
              </TableCell>

              <TableCell
                sortDirection={chargeSortBy === "applicable" ? chargeSortDirection : false}
              >
                <TableSortLabel
                  active={chargeSortBy === "applicable"}
                  direction={chargeSortDirection}
                  onClick={() => handleChargeSort("applicable")}
                >
                  Include
                </TableSortLabel>
              </TableCell>

              <TableCell
                sortDirection={chargeSortBy === "created_at" ? chargeSortDirection : false}
              >
                <TableSortLabel
                  active={chargeSortBy === "created_at"}
                  direction={chargeSortDirection}
                  onClick={() => handleChargeSort("created_at")}
                >
                  Date Created
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {sortedChargeMappings.map((cm) => (
              <TableRow
                key={cm.id}
                sx={{
                  ...(!cm.ack
                    ? activeTheme.palette.mode === "dark"
                      ? DARK_HIGHLIGHTED_ROW_STYLES
                      : HIGHLIGHTED_ROW_STYLES
                    : {}),
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell align="left">{cm.description}</TableCell>
                <TableCell align="left">
                  <MappingTypeSelect
                    role="charge"
                    mapping={cm}
                    setMappings={setMappings}
                    saveUpdate={saveUpdate}
                  />
                </TableCell>
                <TableCell>
                  <ApplicableCheckbox
                    mapping={cm}
                    setMappings={setMappings}
                    saveUpdate={saveUpdate}
                    role="charge"
                  />
                </TableCell>
                <TableCell>{cm.created_at}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* ----- Usages Table ----- */}
      <Typography variant="h5" style={{ marginTop: 20 }}>
        Usages
      </Typography>
      <TableContainer style={{ marginTop: 10 }} component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="Usages Table">
          <TableHead>
            <TableRow>
              <TableCell
                sortDirection={usageSortBy === "description" ? usageSortDirection : false}
              >
                <TableSortLabel
                  active={usageSortBy === "description"}
                  direction={usageSortDirection}
                  onClick={() => handleUsageSort("description")}
                >
                  Description
                </TableSortLabel>
              </TableCell>

              <TableCell sortDirection={usageSortBy === "utility_usage_type_id" ? usageSortDirection : false}>
                <TableSortLabel
                  active={usageSortBy === "utility_usage_type_id"}
                  direction={usageSortDirection}
                  onClick={() => handleUsageSort("utility_usage_type_id")}
                >
                  Usage Type
                </TableSortLabel>
              </TableCell>

              <TableCell
                sortDirection={usageSortBy === "applicable" ? usageSortDirection : false}
              >
                <TableSortLabel
                  active={usageSortBy === "applicable"}
                  direction={usageSortDirection}
                  onClick={() => handleUsageSort("applicable")}
                >
                  Include
                </TableSortLabel>
              </TableCell>

              <TableCell
                sortDirection={usageSortBy === "created_at" ? usageSortDirection : false}
              >
                <TableSortLabel
                  active={usageSortBy === "created_at"}
                  direction={usageSortDirection}
                  onClick={() => handleUsageSort("created_at")}
                >
                  Date Created
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {sortedUsageMappings.map((um) => (
              <TableRow
                key={um.id}
                sx={{
                  ...(!um.ack
                    ? activeTheme.palette.mode === "dark"
                      ? DARK_HIGHLIGHTED_ROW_STYLES
                      : HIGHLIGHTED_ROW_STYLES
                    : {}),
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell align="left">{um.description}</TableCell>
                <TableCell align="left">
                  <MappingTypeSelect
                    role="usage"
                    mapping={um}
                    setMappings={setMappings}
                    saveUpdate={saveUpdate}
                  />
                </TableCell>
                <TableCell>
                  <ApplicableCheckbox
                    mapping={um}
                    setMappings={setMappings}
                    role="usage"
                    saveUpdate={saveUpdate}
                  />
                </TableCell>
                <TableCell>{um.created_at}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
