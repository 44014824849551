import { Typography, Paper } from "@material-ui/core";

export default function PDFText({ bill }) {
  return (
    <div>
      {!!bill.pdf_text && (
        <Paper style={{ marginBottom: "20px" }}>
          <Typography variant="h4" gutterBottom>
            Decoded PDF Text
          </Typography>
          <div
            style={{
              overflow: "auto",
              padding: "10px",
              borderRadius: "4px",
            }}
          >
            <Typography
              component="pre"
              variant="body1"
              style={{ fontFamily: "monospace" }} // Use monospaced font to preserve layout
            >
              {bill.pdf_text}
            </Typography>
          </div>
        </Paper>
      )}
    </div>
  );
}