import CalendarHeatmap from 'react-calendar-heatmap';
import ReactTooltip from 'react-tooltip';
import { useMemo, useContext, useState } from 'react';
import { Select, MenuItem, useTheme } from '@material-ui/core';
import GPTBillContext from '../../../../contexts/GPTBillContext';
import TextractContext from '../../../../contexts/TextractContext';

const { getDaysArray } = require("../../../helpers/date-helpers");

export default function MeterCalendar({ uam }) {
  const [year, setYear] = useState(2024);
  const { gptBill, uams } = useContext(GPTBillContext);
  const { dateRange, selectedTextractBill } = useContext(TextractContext);
  const theme = useTheme();
  const dates = useMemo(() => ({
    startDate: new Date(`${year}-01-01`),
    endDate: new Date(`${year}-12-31`),
  }), [year]);

  const gptCalendarData = useMemo(() => {
    if (!gptBill) return [];
    const lineItems = [...gptBill.charges, ...gptBill.usages].filter(li => li.utility_account_meter_id === uam.id);
    if (!lineItems.length) return [];

    const start = [...lineItems].filter(li => li.applicable && li.start_date).map(li => li.start_date).sort()[0];
    const end = [...lineItems].filter(li => li.applicable && li.end_date).map(li => li.end_date).sort().pop();
    if (!start || !end) return [];

    let startDate = new Date(start);
    let endDate = new Date(end);
    const minimumStart = new Date(new Date().setFullYear(new Date().getFullYear() - 3));
    const maximumEnd = new Date(new Date().setFullYear(new Date().getFullYear() + 1));

    startDate = minimumStart > startDate ? minimumStart : startDate;
    endDate = endDate > maximumEnd ? maximumEnd : endDate;

    const days = getDaysArray(startDate, endDate);

    return days.map(d => ({
      id: "Current Bill",
      start_date: start.slice(0, 10),
      end_date: end.slice(0, 10),
      date: d.toISOString(),
      class: "current-bill-calendar-data",
      link: "",
    }));
  }, [gptBill, uam]);

  const calendarData = useMemo(() => {
    const uamData = uam.calendar_data || [];
    const eventsByDate = {};
    const noDataEvents = uamData.filter(datum => datum.class === "no-data");
    const events = [...uamData.filter(datum => datum.class !== "no-data"), ...gptCalendarData];

    events.forEach(event => {
      const dateKey = event.date.slice(0, 10);
      if (!eventsByDate[dateKey]) {
        eventsByDate[dateKey] = [];
      }
      eventsByDate[dateKey].push(event);
    });

    if (!selectedTextractBill.processed) {
      Object.keys(eventsByDate).forEach(date => {
        if (eventsByDate[date].length > 1) {
          const hasCurrentBill = eventsByDate[date].some(e => e.class === "current-bill-calendar-data");
          const hasSavedData = eventsByDate[date].some(e => e.class.startsWith("saved-data"));
          if (hasCurrentBill && hasSavedData) {
            eventsByDate[date] = eventsByDate[date].map(event => ({
              ...event,
              class: "overlap-calendar-data",
            }));
          }
        }
      });
    }

    const updatedEvents = Object.values(eventsByDate).flat();
    return [...noDataEvents, ...updatedEvents];
  }, [uam.calendar_data, gptCalendarData, selectedTextractBill]);

  return (
    <div style={{ width: "96%", padding: "0px 20px 10px 10px", fill: theme.palette.mode === 'light' ? 'black' : 'white', overflow: 'visible' }}>
      <CalendarHeatmap
        style={{ overflow: 'visible' }}
        startDate={dateRange.start_date || dates.startDate}
        endDate={dateRange.end_date || dates.endDate}
        gutterSize={0}
        values={calendarData}
        tooltipDataAttrs={value => ({
          'data-tip': value.id === "No Data"
            ? `${value.id} - ${value.date.slice(0, 10)}`
            : value.id
              ? `${value.id}: ${value.start_date ? value.start_date.slice(0, 10) : ''} - ${value.end_date ? value.end_date.slice(0, 10) : ''}`
              : `${value.date}`,
        })}
        onClick={value => value.link && window.open(value.link)}
        classForValue={value => !value ? 'color-empty' : value.class}
      />
      <ReactTooltip />
    </div>
  );
}