import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

const ValidationSelector = ({ source, setSource }) => {
  return (
    <FormControl variant="outlined" style={{ width: 300 }}>
      <InputLabel id="source-label">Source</InputLabel>
      <Select
        label="Source"
        labelId="source-label"
        value={source}
        onChange={(e) => setSource(e.target.value)}
        displayEmpty
      >
        <MenuItem value="all">All</MenuItem>
        <MenuItem value="textract">Invoyage</MenuItem>
        <MenuItem value="manual">EP Legacy</MenuItem>
        <MenuItem value="ConsumptionDataUpload">CSV Upload</MenuItem>
        <MenuItem value="ARDEM">ARDEM</MenuItem>
        <MenuItem value="Eneration Onboarding ETL 20250306">Eneration Onboarding ETL 20250306</MenuItem>
        <MenuItem value="Urjanet">Urjanet</MenuItem>
      </Select>
    </FormControl>
  );
};

export default ValidationSelector;
